// NPM Modules
import React from 'react';
import cookie from 'cookie';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { SnackbarProvider } from 'notistack';
// Theme Providers
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import themeGenerator from '../../theme';
// Themes
import DesktopTheme from './themes/Desktop';
import MobileTheme from './themes/Mobile';
// Actions
import organization from '../redux/actions/organization';
import organizationTheme from '../redux/actions/organization/theme';
// Components
import ReleaseNotesDialog from '../components/Dialogs/ReleaseNotesDialog';
// Material-UI
import Slide from '@material-ui/core/Slide';
import Zoom from '@material-ui/core/Zoom';
// Styled Comopnents
const SnackbarContainer = styled.div`
  & > div {
    ${({ isMobile }) => (isMobile ? 'bottom: 62px;' : '')}
  }
`;
/**
 * ThemeProvider
 */
class ThemeProvider extends React.Component {
  // Refs
  snackbarAnchorPoint = React.createRef();
  /**
   * Component State
   */
  state = {
    // Check if server or client then check if route has theme
    theme: this.getTheme(),
    // Do not render
    DNR: false,
    showReleaseNotes: false
  };
  /**
   * Handles getting the init state of the theme.
   */
  getTheme() {
    let route = null;
    if (this.props.location) {
      route = this.findRoute(this.props.location.split('?')[0]);
    } else {
      route = this.findRoute(this.props.history.location.pathname);
    }
    return route.props.notheme ? false : true;
  }
  /**
   * componentDidMount method
   * Attach event handlers here.
   */
  componentDidMount() {
    // Only runs on client
    if (this.props.history) {
      // Listen to changes on the path history to check if we need to update the theme.
      this.props.history.listen(location => {
        const parts = location.pathname.split('/');
        // ['', 'organization', 'org_key', ...]
        if (parts[1] === 'organization' && parts[2] !== 'list') {
          const urlOrgKey = parts[2];
          if (this.props.organization) {
            if (this.props.organization.org_key !== urlOrgKey) {
              this.setState({ DNR: true });
              this.props.getOrganization({ org_key: urlOrgKey });
              this.props.getOrganizationTheme({ org_key: urlOrgKey });
            }
          } else {
            this.setState({ DNR: true });
            this.props.getOrganization({ org_key: urlOrgKey });
            this.props.getOrganizationTheme({ org_key: urlOrgKey });
          }
        }
        const route = this.findRoute(location.pathname);
        if (route && route.props.notheme && this.state.theme) {
          this.setState({ theme: false });
        } else if (route && !route.props.notheme && !this.state.theme) {
          this.setState({ theme: true });
        } else if (!route && !this.state.theme) {
          this.setState({ theme: true });
        }
      });
    }
    this.shouldReleaseNotesOpen();
  }
  /**
   * componentDidUpdate method
   */
  componentDidUpdate(prevProps) {
    if (
      this.state.DNR &&
      prevProps.organizationStatus === 'get' &&
      this.props.organizationStatus === 'success'
    ) {
      this.setState({ DNR: false });
    }
  }
  /**
   * findRoute
   */
  findRoute(path) {
    const routes = this.props.children.props.children;
    const pathParts = path.split('/');
    const route = routes.find((route, index) => {
      if (!route.props.path) {
        return route;
      }
      const routeParts = route.props.path.split('/');
      if (pathParts.length !== routeParts.length) return false;
      const parts = routeParts.map((part, index) => {
        // Skip id parts.
        if (part.charAt(0) === ':') return true;
        return part === pathParts[index];
      });
      return parts.every(part => part);
    });
    return route;
  }
  /**
   * If cookie['show-rn'] is a version behind show release notes
   */
  shouldReleaseNotesOpen() {
    const VERSION = '1.10.1';
    const releaseNotesCookie =
      typeof document === 'undefined'
        ? null
        : cookie.parse(document.cookie)[`show-rn`] || null;

    if (releaseNotesCookie !== VERSION) {
      // Set cookie
      const newCookie = cookie.serialize('show-rn', VERSION, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365
      });
      document.cookie = newCookie;
      this.setState({ showReleaseNotes: true });
    }
  }
  /**
   * Updateds state.showReleaseNotes to false
   */
  handleReleaseNotesClose = () => {
    this.setState({ showReleaseNotes: false });
  };
  /**
   * render method
   * @return {Object} JSX
   */
  render() {
    // Setup base body for rendering app
    let body = <React.Fragment>{this.props.children}</React.Fragment>;
    // Find out if the current route has no theme.
    if (this.state.theme) {
      if (this.props.isMobile) {
        // Return Mobile Theme
        body = (
          <MobileTheme>
            <ReleaseNotesDialog
              open={this.state.showReleaseNotes}
              onClose={this.handleReleaseNotesClose}
            />
            {body}
          </MobileTheme>
        );
      } else {
        // Desktop Theme
        body = (
          <DesktopTheme>
            <ReleaseNotesDialog
              open={this.state.showReleaseNotes}
              onClose={this.handleReleaseNotesClose}
            />
            {body}
          </DesktopTheme>
        );
      }
    }
    if (this.state.DNR) {
      body = <h1>LOADING...</h1>;
    }
    const theme = themeGenerator(this.props.organizationTheme, this.props.ui);
    // Render
    return (
      <StyledThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: this.props.isMobile ? 'center' : 'left'
            }}
            TransitionComponent={this.props.isMobile ? Zoom : Slide}
            domRoot={this.snackbarAnchorPoint.current}
          >
            <React.Fragment>
              {body}
              <SnackbarContainer
                isMobile={this.props.isMobile}
                ref={this.snackbarAnchorPoint}
              />
            </React.Fragment>
          </SnackbarProvider>
        </MuiThemeProvider>
      </StyledThemeProvider>
    );
  }
  /**
   * Map the state of redux store to the props of this component
   * @param {Object} state redux state
   * @return {Object} object of states from store
   */
  static mapStateToProps(state) {
    return {
      ui: state.ui,
      user: state.user.info.info,
      isMobile: state.device.isMobile,
      organization: state.organization.info.info,
      organizationTheme: state.organization.theme.info.info,
      organizationStatus: state.organization.info.status
    };
  }
  /**
   * Map the dispatch functions to the props of this component
   * @param {Object} dispatch
   * @return {bindActionCreators} action creators
   */
  static mapDispatchToProps(dispatch) {
    return bindActionCreators(
      {
        getOrganization: organization.info.get,
        getOrganizationTheme: organizationTheme.info.get
      },
      dispatch
    );
  }
}
/**
 * Export Module (React Component)
 * Wrap module in redux state connect for data and dispatching.
 */
export default connect(
  ThemeProvider.mapStateToProps,
  ThemeProvider.mapDispatchToProps
)(ThemeProvider);
